import React from "react";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate, useSelect, useParsed } from "@refinedev/core";

import EditForm from "../../components/EditForm"

import { columnConfig } from "./config";
import internal from "stream";


export const VendorsCreate: React.FC<IResourceComponentsProps> = () => {

  const resource = "vendors";

  const { id } = useParsed();

  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: resource,
      id,
      action: "create",
    }
  }
  );

  return (
    <Create
      saveButtonProps={saveButtonProps} >
      <EditForm
        resource={resource}
        columnConfig={columnConfig}
        columnData={{}}
        register={register}
        control={control}
        errors={errors}
        type={"create"}
      />
    </Create>
  )
};
