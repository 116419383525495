

import Box from "@mui/material/Box";

export default function AppTitle(collapsed: any) {
    return (
        <div>
            {
                collapsed.collapsed ?
                    <Box
                        component="img"
                        sx={{
                            m: 2,
                            // ml: 4,
                            // height: 150,
                            width: 40,
                            // maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 150, md: 150 },
                        }
                        }
                        src="/images/VendorDocs_icon.png" alt="VendorDocs"
                    /> :

                    <Box
                        component="img"
                        sx={{
                            m: 2,
                            // ml: 4,
                            // height: 150,
                            width: 155,
                            // maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 200, md: 200 },
                        }
                        }
                        src="/images/VendorDocs_logo_horizontal.png" alt="VendorDocs"
                    />}
            {/* <img src="/images/ComparABill_logo.png" alt="Logo" /> */}
            {/* <span>ComparABill</span> */}
        </div>
    );

}