import React, { useState } from "react";

import { Box, TextField, Autocomplete, Select, InputLabel, MenuItem, FormControl, FormHelperText, Stack, Paper, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/

interface Props {
    id?: number,
    name?: any,
    label?: any,
    control?: any,
    defaultValue?: string,
    required?: any,
    error?: any,
    helperText?: any,
    options?: any,
    variant?: string,
    margin?: string,
    props?: any
}

const StampField = ({
    name,
    label,
    control,
    defaultValue,
    required,
    error,
    helperText,
    options,
    ...props
}: Props) => {

    const stampFields = ["Job / Department #", "Group", "Company Name", "Vendor I.D.", "PO / Sub #", "Activity / Cost Code"];

    // const stampFields = ["Date Received", "Job / Department #", "Group", "Company Name", "Vendor ID", "PO / Sub #", "Activity / Cost Code"];


    const labelId = `${name}-label`;



    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            rules={{ required: required }}
            name={name}
            render={({ field }) => {
                return (
                    <><Paper sx={{ p: 2, width: 300 }}><Stack spacing={2}>
                        <Typography >Stamp</Typography>
                        {stampFields.map((item) => {
                            return (
                                <TextField
                                    key={item}
                                    label={item}
                                    defaultValue={defaultValue[item] || ""}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        var mergedFieldValue = { ...field.value, [item]: value }
                                        Object.keys(mergedFieldValue).forEach((key) => !(mergedFieldValue[key]) && delete mergedFieldValue[key]);
                                        field.onChange(mergedFieldValue)
                                    }}

                                />)
                        })}
                    </Stack></Paper>
                    </>
                )

            }}
        />
    )
}

export default StampField;

