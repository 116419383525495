
import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
    useShow,
    useList,
    IResourceComponentsProps,
    useTranslate,
    useMany,
    useGetIdentity,
    useResource,
    useGo
} from "@refinedev/core";

import { useOne, HttpError } from "@refinedev/core";

import {
    Show,
    TextFieldComponent as TextField,
    TagField,
    NumberField,
} from "@refinedev/mui";

import {
    Box,
    Card,
    Typography,
    CardActionArea,
    CardContent,
    ButtonBase
} from '@mui/material';

import Stack from '@mui/material/Stack';

interface IVendors {
    id: number;
    Name: string;
    total: number;
}

interface StatCardProps {
    title: string;
    value: number;
    icon: any;
}

import { supabaseClient } from "utility";

export const TableStatCard: React.FC = ({ identifier, filter }) => {

    const [showCard, setShowCard] = useState(false);

    const { resource: _resource } = useResource(identifier);

    const [rowCount, setRowCount] = useState();


    const { data: identity } = useGetIdentity<{ id: number, Features: string }>();

    useEffect(() => {
        if (identity && identity?.Features.split(',').includes(identifier)) {

            setShowCard(true);

            var sb = supabaseClient.from(_resource?.name).select('*', { count: 'exact', head: true })

            if (_resource?.meta?.profileFilter) {
                sb = sb.eq(_resource.meta.profileFilter, identity?.id)
            }

            if (filter) {
                if (filter.operator === "eq") {
                    sb = sb.eq(filter.field, filter.value)
                } else if (filter.operator === "is") {
                    sb = sb.is(filter.field, filter.value)
                }
            }
            sb.then(record => {
                setRowCount(record?.count);
            });
        }
    }, [identity])

    return (<>
        {showCard &&
            <Card m={1} pt={1}>

                <CardActionArea component={RouterLink} to={_resource.list} >
                    <CardContent>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box width="100%" m="30px">
                                {_resource.icon}
                                <TextField fontWeight="bold" value={_resource?.meta?.label} />
                            </Box>
                            <Box width="100%" >
                                <TextField fontWeight="bold" value={rowCount} />
                            </Box>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card >
        } </>
    );
};

