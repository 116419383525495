import { createClient } from "@refinedev/supabase";

export const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_URL?.toString() as string,
  process.env.REACT_APP_SUPABASE_KEY?.toString() as string, {
  db: {
    schema: "public",
  },
  auth: {
    persistSession: true,
  },
});
