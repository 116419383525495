
import React, { useEffect, useMemo, useState } from 'react';

import { useList, useTranslate, useGetLocale, HttpError, useResource, useParsed } from "@refinedev/core";

import {
    EditButton,
    ShowButton,
    DeleteButton,
    CreateButton,
} from "@refinedev/mui";

import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
} from 'material-react-table';


import {
    Box,
} from '@mui/material';

import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';


import flattenDict from "./flattenDict";
import { set } from 'react-hook-form';

// import internal from "stream";
// import { userFriendlySecond } from "@refinedev/core/dist/definitions";

type RemoteDataTableProps = {
    resource: string;
    ColumnConfig: any;
    base_filters?: any;
    initial_sorting?: any;
    searchable?: boolean;
    createItem?: boolean;
    editItem?: boolean;
    showItem?: boolean;
    deleteItem?: boolean;
    statusSelectComponent?: any;
    editMeta?: any;
    createMeta?: any;
}

// import internal from "stream";

export const RemoteDataTable: React.FC<RemoteDataTableProps> = ({
    resource,
    ColumnConfig,
    base_filters,
    initial_sorting,
    searchable = false,
    createItem = true,
    editItem = true,
    showItem = false,
    deleteItem = false,
    statusSelectComponent,
    editMeta,
    createMeta
}) => {
    const translate = useTranslate();

    const [tableData, setTableData] = useState([{}]);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const { params } = useParsed();

    //table state
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>(initial_sorting || []);



    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 25,
    });

    const { resource: my_resource } = useResource();

    const profileFilter = my_resource?.meta?.profileFilter

    // Extract columns configuration for MaterialReactTable from ColumnConfig

    const config = useMemo(() => {

        var search_columns: string[] = [];
        var search_headers: string[] = [];
        var column_visibility: { [id: string]: boolean; } = {};
        var list_columns: any[] = [];
        var list_query: string[] = []

        ColumnConfig.forEach((item: any) => {
            if (item?.list) {
                if (item.query !== null) {
                    list_query.push(item.query ? item.query : item.field);
                }
                list_columns.push({
                    accessorKey: item.field,
                    header: translate(item.header),
                    ...item.list
                })
                if (item?.search === true) {
                    search_columns.push(item.field);
                    search_headers.push(translate(item.header));
                }
                if (item?.list?.show !== undefined) {
                    column_visibility[item.field] = item.list.show;
                }
            }
        });

        return ({
            search: {
                columns: search_columns,
                headers: search_headers,
            },
            visibility: column_visibility,
            list: list_columns,
            query: list_query
        })

        // }, [ColumnConfig, base_filters])
    }, [ColumnConfig, base_filters])



    // var search_columns: string[] = [];
    // var search_headers: string[] = [];
    // var column_visibility: { [id: string]: boolean; } = {};
    // var list_columns: any[] = [];
    // var list_query: string[] = []

    // ColumnConfig.forEach((item: any) => {
    //     if (item?.list) {
    //         if (item.query !== null) {
    //             list_query.push(item.query ? item.query : item.field);
    //         }
    //         list_columns.push({
    //             accessorKey: item.field,
    //             header: translate(item.header),
    //             ...item.list
    //         })
    //         if (item?.search === true) {
    //             search_columns.push(item.field);
    //             search_headers.push(translate(item.header));
    //         }
    //         if (item?.list?.show !== undefined) {
    //             column_visibility[item.field] = item.list.show;
    //         }
    //     }
    // });

    // const table_columns = useMemo<MRT_ColumnDef<any>[]>(
    //     () => list_columns,
    //     [],
    // );


    const processFilters = (global_filter: string, column_filter: any) => {
        var result = [];

        if (base_filters) {
            result = [...base_filters];
        }

        if (params?.filters) {
            result = [...result, ...params?.filters]
        }

        if (profileFilter !== undefined) {
            const identity = JSON.parse(localStorage.getItem("profile"));

            var filter_list: Array<any> = [];

            profileFilter.split(',').forEach((filter_field) => {
                filter_list.push({ field: filter_field, operator: "eq", value: identity.id })
                if (identity?.alternates) {
                    identity.alternates.forEach((item) => {
                        filter_list.push({ field: filter_field, operator: "eq", value: item.id })
                    })
                }
            });

            if (filter_list.length === 1) {
                result.push(filter_list[0])
            } else {
                result.push({ operator: "or", value: filter_list })
            }
        }

        if (global_filter) {
            if (config.search.columns.length > 1) {

                // if (search_columns.length > 1) {
                var result_or = {
                    operator: "or",
                    value: []
                }
                config.search.columns.forEach((item) => {
                    const result: { [id: string]: string; } = {
                        field: item,
                        operator: "contains",
                        value: global_filter
                        // value: '*' + global_filter + '*'
                    };

                    result_or.value.push(result);
                })
                console.log("OR PUSH", result_or);

                result.push(result_or);

            } else if (config.search.columns.length === 1) {
                result.push({
                    field: config.search.columns[0],
                    operator: "contains",
                    value: global_filter
                })
            }
        }

        column_filter.map((item: any) => {
            const col_config = ColumnConfig.find((col) => col.field === item.id);
            if (col_config.list?.filterFn == "between") {
                if (item.value[0] !== undefined) {
                    result.push({
                        field: item.id,
                        operator: "gte",
                        value: item.value[0]
                    });
                }

                if (item.value[1] !== undefined) {
                    result.push({
                        field: item.id,
                        operator: "lte",
                        value: item.value[1]
                    });
                }

            }
            else if (col_config.list?.filterFn !== undefined) {
                result.push({
                    field: item.id,
                    operator: col_config.list?.filterFn,
                    value: item.value
                })
            }
            else {
                result.push({
                    field: item.id.replace('-', '.'),
                    operator: "contains",
                    value: item.value
                })
            }
        }

        );
        return result;
    }

    const [localization, setLocalization] = useState(MRT_Localization_EN);
    const locale = useGetLocale();

    useEffect(() => {
        if (locale() === "fr") {
            setLocalization(MRT_Localization_FR);
        } else {
            setLocalization(MRT_Localization_EN);
        }
    },
        [locale,])


    const { data, isLoading, isError } = useList({
        resource: resource,
        meta: {
            select: config.query.join(','),
        },
        pagination: {
            current: pagination.pageIndex + 1,
            pageSize: pagination.pageSize
        },
        sorters: sorting.map((item => ({ field: item.id.includes('-') ? (item.id.replace('-', '(') + ')') : (item.id), order: item.desc ? "desc" : "asc" }))),
        filters: processFilters(globalFilter, columnFilters)
    });


    useEffect(() => {
        if (data?.data) {
            setRowCount(data.total);
            const _data = data.data.map((item) => {
                return (flattenDict(item));
            })
            setTableData(_data);
        } else {
            setTableData([]);
        }
    }, [
        data,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    const renderRowActions = ({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            {editItem && <EditButton hideText meta={editMeta} recordItemId={row.id} />}

            {deleteItem && <DeleteButton resource={resource} hideText recordItemId={row.id} />}

            {showItem && <ShowButton resource={resource} hideText recordItemId={row.id} />}

            {/* <Tooltip title="Delete">
                <IconButton color="error" onClick={() => console.log("DELETE",row)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}
        </Box>
    );

    const renderTopToolbarCustomActions = ({ table }) => (
        <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
            {statusSelectComponent}
            {createItem && <CreateButton color="secondary" meta={createMeta}  />}
        </Box>
    )

    return (
        <MaterialReactTable
            style={{ width: '99%' }}
            // columns={table_columns}
            columns={config.list}
            data={tableData}
            // enableRowSelection
            defaultColumn={{ minSize: 40, maxSize: 1000, size: 100 }}
            getRowId={(row: any) => row.id}
            initialState={{
                showColumnFilters: false,
                density: "compact",
                showGlobalFilter: true,
                // columnVisibility: column_visibility,
                columnVisibility: config.visibility,
                sorting: sorting
            }}
            positionGlobalFilter='left'
            muiSearchTextFieldProps={{
                // placeholder: `Search ${search_headers.join(',')}`,
                placeholder: `Search ${config.search.headers.join(',')}`,
                sx: { minWidth: '300px' },
                variant: 'outlined',
            }}
            manualFiltering
            manualPagination
            manualSorting
            muiToolbarAlertBannerProps={
                isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                    }
                    : undefined
            }
            enableFilterMatchHighlighting={false}
            onColumnFiltersChange={(value) => { setPagination({ ...pagination, pageIndex: 0 }); setColumnFilters(value) }}
            onGlobalFilterChange={(value) => { setPagination({ ...pagination, pageIndex: 0 }); setGlobalFilter(value) }}
            onPaginationChange={setPagination}
            onSortingChange={(value) => { setPagination({ ...pagination, pageIndex: 0 }); setSorting(value) }}
            rowCount={rowCount}
            // editingMode={'cell'}
            // editDisplayMode={'table'}
            // enableEditing={true}
            // enableRowActions={true}            
            // muiTableBodyCellProps={
            //     ({ table, cell }) => ({
            //         onChange: (event) => { console.log("ON BLUR!",table, cell); console.log("CALUE", event.target)}
            //         // onClick: () => { console.log("ON CHANGE!")}
            //         //     onClick: () => {
            //         //       table.setEditingCell(cell);
            //         //     },
            //         //   }
            // })}
            // muiTableBodyCellProps: ({ table, cell }) => ({
            //     onClick: () => {
            //       table.setEditingCell(cell);
            //     },
            //   }),
            // // onEditingCellChange={(event) => { console.log("VALUE CHANGE",event)}}
            // onEditingRowChange={(event) => { console.log("EDITING CHANGE!",event)}}
            state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
            }}
            enableRowActions={(editItem || showItem)}
            renderRowActions={renderRowActions}
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            localization={localization}
        />
    );

};
