import React, { useState, useMemo, useEffect } from "react";
import { Edit, Create } from "@refinedev/mui";
import { Box, TextField, FormControl, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate, useSelect, useParsed } from "@refinedev/core";

import { NumericFormat, NumericFormatProps } from 'react-number-format';
import SelectField from "./SelectField"
import BooleanField from "./BooleanField"
import AutoCompleteField from "./AutoCompleteField";
import MultipleSelectField from "./MultipleSelectField";
import StampField from "./StampField";

// import { statusOptions, invoiceSourceOptions } from "./config";



import internal from "stream";
import { PropaneSharp, TimesOneMobiledata } from "@mui/icons-material";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCurrency = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                valueIsNumericString
                prefix="$"
            />
        );
    },
);


const FormField = ({ item, config, control, columnData, register, errors }) => {

    const translate = useTranslate();

    const isError = !!(errors as any)[item.field];
    const errorText = isError && (((errors as any)[item.field]?.message) || "This field is required.");
    if (item.widget === "AutoComplete") {
        return (
            <AutoCompleteField
                key={item.field}
                id={item.field}
                control={control}
                resource={item.resource}
                field={item.field}
                name={item.resourceField}
                defaultValue={columnData[item.field] || item.defaultValue}
                label={translate(item.header)}
                required={item.required}
                error={isError}
                helperText={errorText}
                multiple={item.multiple || false}
            />
        );
    } else if (item.widget === "SelectField") {
        return (
            <SelectField
                key={item.field}
                id={item.field}
                name={item.field}
                label={item.header}
                control={control}
                defaultValue={columnData[item.field] || item.defaultValue || ""}
                required={item.required ? true : false}
                variant="outlined"
                error={isError}
                helperText={errorText}
                margin="normal"
                options={item.options}
            />
        )
    } else if (item.widget === "MultipleSelectField") {
        return (
            <MultipleSelectField
                key={item.field}
                id={item.field}
                name={item.field}
                label={item.header}
                control={control}
                defaultValue={columnData[item.field] || item.defaultValue || ""}
                required={item.required ? true : false}
                variant="outlined"
                error={isError}
                helperText={errorText}
                margin="normal"
                options={item.options}
            />
        )
    }
    else if (item.widget === "TextArea") {
        return (
            <TextField
                key={item.field}

                {...register(item.field, {
                    required: item.required,
                })}
                defaultValue={columnData[item.field] || item.defaultValue}
                error={isError}
                helperText={errorText}
                margin="normal"
                multiline
                rows={4}
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate(item.header)}
                name={item.field}
                required={item.required ? true : false}
            />
        )
    }
    else if (item.widget === "Checkbox") {
        console.log("BOOLEAN", columnData[item.field]);
        return (
            <BooleanField
                key={item.field}
                {...register(item.field, {
                    required: item.required,
                })}
                ref={null}
                control={control}
                error={isError}
                helperText={errorText}
                margin="normal"
                defaultValue={item.field ? columnData[item.field] : item.defaultValue}
                label={translate(item.header)}
                name={item.field}
                required={item.required ? true : false}
            />
        )
    } else if (item.widget === "Number") {
        return (
            <TextField
                key={item.field}

                {...register(item.field, {
                    required: item.required,
                })}
                InputProps={{ ...item.range, inputMode: 'numeric' }}
                defaultValue={columnData[item.field] || item.defaultValue}
                error={isError}
                helperText={errorText}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                label={translate(item.header)}
                name={item.field}
                required={item.required ? true : false}
            />
        )
    } else if (item.widget === "Currency") {
        return (
            <TextField
                key={item.field}
                {...register(item.field, {
                    required: item.required,
                })}
                InputProps={{ ...item.range, inputComponent: NumericFormatCurrency as any }}
                defaultValue={columnData[item.field] || item.defaultValue}
                error={isError}
                helperText={errorText}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={translate(item.header)}
                name={item.field}
                required={item.required ? true : false}
            />
        )
    } else if (item.widget === "StampField") {
        return (
            <StampField
                key={item.field}
                id={item.field}
                name={item.field}
                label={item.header}
                control={control}
                defaultValue={columnData[item.field] || item.defaultValue || ""}
                required={item.required ? true : false}
                variant="outlined"
                error={isError}
                helperText={errorText}
                margin="normal"
                options={item.options}
            />
        )
    } else {
        return (
            <TextField
                key={item.field}

                {...register(item.field, {
                    required: item.required,
                })}
                defaultValue={columnData[item.field] || item.defaultValue}
                error={isError}
                // helperText={(errors as any)[item.field]?.message}
                helperText={errorText}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label={translate(item.header)}
                name={item.field}
                required={item.required ? true : false}
            />
        )
    }

}


const EditForm = ({ columnConfig, control, columnData, register, errors, ...props }) => {
    const config = useMemo(() => {
        var edit_columns: any[] = [];
        var edit_query: string[] = [];


        columnConfig.forEach((item: any) => {
            if (item?.edit) {
                edit_query.push(item?.edit?.query ? item.edit.query : item.field);

                edit_columns.push({
                    field: item.field,
                    header: item.header,
                    defaultValue: columnData ? columnData[item.field] : undefined,
                    ...item.edit
                })
            }
        });

        return ({
            columns: edit_columns,
            query: edit_query
        })
    }, [columnConfig]);

    return (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            autoComplete="off"
        >
            <Grid container columnSpacing={2} >
                <Grid item xs={12} sm={6}>
                    {columnData && config.columns.map((item: any) => {
                        if (item.show === false) {
                            return ("")
                        } else if (item.gridcol === undefined || item.gridcol == 1) {
                            return (
                                // <Grid item xs={12} sm={6}>
                                <FormField
                                    item={item}
                                    config={config}
                                    control={control}
                                    columnData={columnData}
                                    register={register}
                                    errors={errors}
                                />
                                // </Grid>
                            )
                        }
                    })
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {columnData && config.columns.map((item: any) => {
                        if (item.show === false) {
                            return ("")
                        } else if (item.gridcol && item.gridcol == 2) {
                            return (
                                // <Grid item xs={12} sm={6}>
                                <FormField
                                    item={item}
                                    config={config}
                                    control={control}
                                    columnData={columnData}
                                    register={register}
                                    errors={errors}
                                />
                                // </Grid>
                            )
                        }
                    })
                    }
                </Grid>                
            </Grid>
        </Box>
    )
}

export default EditForm;