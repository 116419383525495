
import { UrlField } from "@refinedev/mui";
import { useNotification} from "@refinedev/core";
import { supabaseClient } from "utility";
import fileDownload from "js-file-download";

interface Props {
    value?: any
}

export const DownloadUrlField = ({ value }: Props) => {
    const { open, close } = useNotification();

    const handleDownload = async (path: string, filename: string) => {

        const { data, error } = await supabaseClient
            .storage
            .from('uploads')
            .download(path);

        if (error) {
            open?.({
                type: "error",
                message: "Download Error",
                description: error.toString()
            });
        } else {
            fileDownload(data, filename);
        }
    };

    const json_data = JSON.parse(value);
    if (json_data?.length > 0) {
        const path = json_data[0]?.path.replace('download/', 'Test');
        return (
            <UrlField
                value={path}
                component="button"
                onClick={() => { handleDownload(path, json_data[0].title) }}
            >{json_data[0]?.title}</UrlField>
        );
    } else {
        return ("");
    }

}
