
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const downloadersTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];



export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Downloaders.fields.id",
        type: "number",
        list: {
            show: false
        }
    },
    {
        field: "Name",
        header: "Downloaders.fields.Name",
        search: true,
        list: {
        }
    },
    {
        field: "Status",
        // flex: 1,
        header: "Downloaders.fields.Status",
        list: {
        }
        // editOptions: {
        //     default: "",
        //     type: "string",
        //     title: "Status Message",
        //     ui: {
        //       "ui:emptyValue": "",
        //     },  

        //   },

    },
    {
        field: "StatusMessage",
        // flex: 1,
        header: "Downloaders.fields.StatusMessage",
        list: {
        }
    },

    // {
    //     name: "PortalsCount",
    //     label: "Portals",
    //     list: true,
    //     edit: false,
    //   },

];


