import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { useTranslate } from "@refinedev/core";


import HomeIcon from "@mui/icons-material/Cottage";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DescriptionIcon from '@mui/icons-material/Description';
// import BuildIcon from "@mui/icons-material/Build";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';


import {
    TableStatCard,
} from "../components/dashboard";

export const DashboardPage: React.FC = () => {

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyNotifications"}
                    filter={{
                        field: "Resolved",
                        operator: "is",
                        value: false
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyVendors"}
                />
            </Grid>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyLogins"}
                    filter={{
                        field: "Status",
                        operator: "eq",
                        value: "Active"
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyAccounts"}
                    filter={{
                        field: "Status",
                        operator: "eq",
                        value: "Active"
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyBills"}
                />
            </Grid>
            <Grid item xs={3}>
                <TableStatCard
                    identifier={"MyPayments"}
                    filter={{
                        field: "ExportID",
                        operator: "is",
                        value: null
                    }}
                />
            </Grid>
        </Grid>
    );
};