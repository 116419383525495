
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const loginsTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];

import StampText from "../../components/StampText";

import { GoLink } from "../../components/GoLink";

export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Logins.fields.id",
        type: "number",
        list: {
            show: false
        },
        edit: {
            show: false
        }
    },
    {
        field: "Name",
        header: "Logins.fields.Name",
        search: true,
        list: {
        },
        edit: {
            required: true,
        }
    },
    {
        query: "clients(Name)",
        // query: "logins!inner(Name)",
        field: "clients-Name",
        // flex: 1,
        header: "Logins.fields.Clients",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Companies",
                            resource: "clients", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "id",
                                    operator: "eq",
                                    value: cell?.row?.original?.logins_id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        query: "vendors(Name)",
        // query: "logins!inner(Name)",
        field: "vendors-Name",
        // flex: 1,
        header: "Logins.fields.Vendors",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Vendors",
                            resource: "vendors", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "id",
                                    operator: "eq",
                                    value: cell?.row?.original?.logins_id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        query: "locations(Name)",
        // query: "logins!inner(Name)",
        field: "locations-Name",
        // flex: 1,
        header: "Logins.fields.Properties",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Properties",
                            resource: "locations", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "id",
                                    operator: "eq",
                                    value: cell?.row?.original?.logins_id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },    
        
    // {
    //     query: "vendors!inner(Name,coordinator_id,coordinator)",
    //     field: "vendors-coordinator_id",
    //     header: "Logins.fields.Coordinator_id",
    //     list: {
    //         show: false
    //     }
    // },
    {
        field: "vendors_id",
        header: "Logins.fields.Vendor",
        // search: true,
        edit: {
            show: true,
            widget: "AutoComplete",
            resource: "vendors",
            resourceField: "Name",
            required: true
        }
    },
    {
        field: "clients_id",
        header: "Logins.fields.Clients",
        // search: true,
        edit: {
            show: true,
            widget: "AutoComplete",
            resource: "clients",
            resourceField: "Name",
            required: true
        }
    },

    // {
    //     query: "downloaders!inner(Name)",
    //     field: "downloaders-Name",
    //     // flex: 1,
    //     header: "Logins.fields.Downloader",
    //     list: {
    //     }
    // },
    // {
    //     field: "downloaders_id",
    //     header: "Logins.fields.Downloader",
    //     // search: true,
    //     edit: {
    //         show: true,
    //         widget: "AutoComplete",
    //         resource: "downloaders",
    //         resourceField: "Name"
    //     }
    // },
    {
        field: "Stamp",
        header: "Clients.fields.Stamp",
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} /> 
        },
        edit: {
            gridcol:2,
            widget: "StampField"
        }
    },
    {
        field: "Status",
        // flex: 1,
        header: "Logins.fields.Status",
        list: {
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: "Active" },
                { value: "Inactive" },
                { value: "Error" },
                { value: "Unsupported" },
                { value: "Suspended" }
            ],
            defaultValue: "Active",
            required: true
        }
    },
    {
        field: "StatusMessage",
        // flex: 1,
        header: "Logins.fields.StatusMessage",
        list: {
        },
        edit: {}
    },
    {
        field: "URI",
        // flex: 1,
        header: "Logins.fields.URI",
        list: {
            show: false
        },
        edit: {
            required: true
        }
    },
    {
        field: "Username",
        // flex: 1,
        header: "Logins.fields.Username",
        list: {
            show: false
        },
        edit: {
            required: true
        }
    },
    {
        field: "Password",
        // flex: 1,
        header: "Logins.fields.Password",
        edit: {
            required: true
        }
    },
    {
        field: "Config",
        // flex: 1,
        header: "Logins.fields.Config",
        list: {
            show: false
        },
        edit: {
        }
    },
    {
        field: "Notes",
        // flex: 1,
        header: "Logins.fields.Notes",
        edit: {
            widget: "TextArea"
        }
    },
    {
        field: "Folder",
        // flex: 1,
        header: "Logins.fields.Folder",
        list: {
            show: false
        },
        edit: {
            required: false
        }
    },
    {
        field: "Refresh",
        // flex: 1,
        header: "Logins.fields.Refresh",
        list: {
            show: false
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: "Monthly" },
                { value: "Daily" },
                { value: "BiMonthly" },
                { value: "MonthDay" }
            ],
            defaultValue: "Monthly",
            required: true
        }
    },
    {
        field: "RefreshOffset",
        // flex: 1,
        header: "Logins.fields.RefreshOffset",
        list: {
            show: false
        },
        edit: {
            widget: "Number",
            range: { min: -31, max: 31 },
            defaultValue: 0
        }
    },
    {
        field: "login_accounts",
        // flex: 1,
        header: "Logins.fields.Accounts",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Accounts",
                            resource: "accounts", // resource name or identifier
                            action: "list"
                        }}
                        query={{
                            filters: [
                                {
                                    field: "logins_id",
                                    operator: "eq",
                                    value: cell?.row?.id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        field: "last_checked",
        // flex: 1,
        header: "Logins.fields.LastChecked",
        list: {
        }
    },
];


