
import React, { useEffect, useMemo, useState } from 'react';

import { columnConfig } from "./config";
import LoadingButton from "@mui/lab/LoadingButton";

import { RemoteDataTable } from 'components/RemoteDataTable';

import { useResource } from "@refinedev/core";

import { Paper, Stack, FormGroup, FormControlLabel, Checkbox, Button } from "@mui/material";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

import { supabaseClient } from "../../utility/supabaseClient";

import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";


// import internal from "stream";

export const NotificationsList: React.FC<IResourceComponentsProps> = () => {

    const [showResolved, setShowResolved] = useState(false);
    const [baseFilters, setBaseFilters] = useState([]);

    const [loadingExport, setLoadingExport] = useState(false);

    const { resource: my_resource } = useResource();

    const profileFilter = my_resource?.meta?.profileFilter

    useEffect(() => {
        var base_filters: any[] = [];
        if (showResolved === false) {
            base_filters.push({
                field: "Resolved",
                operator: "is",
                value: false
            })
        }
        setBaseFilters(base_filters);
    }, [, showResolved]);


    const handle_check_vendordocs = () => {

        setLoadingExport(true);

        console.log("Check Vendordocs invoked!");

        supabaseClient.functions.invoke("vendordocs-audit"
            // supabaseClient.functions.invoke(
            //     "vendordocs/audit", {
            //         "body": { "name": "Jason" }
            //     }
        ).then(({ data }) => {
            console.log("EDGE FUNCTION DATA", data)
            setLoadingExport(false);
        }).catch(({ error }) => {
            console.log("EDGE FUNCTION ERROR", error)
            setLoadingExport(false);
        });
    };

    return (

        <>
            <Paper elevation={3} sx={{ mt: 2, padding: 2 }}>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox defaultChecked={showResolved} />}
                            label="Show Resolved"
                            onChange={() => {
                                setShowResolved(!showResolved);
                            }}
                        />
                    </FormGroup>

                    {!profileFilter && (
                        <LoadingButton
                            loading={loadingExport}
                            loadingPosition="start"
                            startIcon={<ChecklistRtlIcon />}
                            variant="contained"
                            onClick={handle_check_vendordocs}
                        >
                            Check Database
                        </LoadingButton>
                    )}
                </Stack>
            </Paper>

            <RemoteDataTable
                resource={"notifications"}
                ColumnConfig={columnConfig}
                base_filters={baseFilters}
            />

        </>
    )


}
