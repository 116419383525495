import React, { useState, useEffect } from "react";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useParsed } from "@refinedev/core";

import EditForm from "../../components/EditForm"

import { columnConfig } from "./config";
import internal from "stream";

export const BillsEdit: React.FC<IResourceComponentsProps> = () => {

  const resource = "logins";

  const { id } = useParsed();

  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish, redirect, mutationResult },
    register,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: resource,
      id,
      action: "edit",
    }
  }
  );

  const [columnData, setColumnData] = useState(queryResult?.data?.data);

  useEffect(() => {
    setColumnData(queryResult?.data?.data);
  },[queryResult]);

  return (
    <Edit
      saveButtonProps={saveButtonProps} >

      <EditForm
        resource={resource}
        columnConfig={columnConfig}
        columnData={columnData}
        register={register}
        control={control}
        errors={errors}
        type={"edit"}
      />
    </Edit>

  )
};
