
import { ChipField } from "../../components/ChipField";

import { GoLink } from "../../components/GoLink";

import StampText from "components/StampText";

export const vendorTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];


export const columnConfig = [
    {
        field: "id",
        header: "Vendors.fields.id",
        type: "number",        
        list: {
            show: false
        },
        edit: {
            show: false
        }

    },
    {
        field: "Name",
        header: "Vendors.fields.Name",
        search: true,
        list: {
        },
        edit: {
            required: true
        }
    },
    {
        field: "Type",
        // flex: 1,
        header: "Vendors.fields.Type",
        list: {
            filterFn: "eq"
        },
        edit:{
            widget: "SelectField",
            options: vendorTypes,
            required: true,
            defaultValue: "Non-Telco"
        }
    },
    {
        field: "Stamp",
        header: "Vendors.fields.Stamp",
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} /> 
        },
        edit: {
            gridcol: 2,
            widget: "StampField"
        }
    },
    {
        field: "Status",
        // flex: 1,
        header: "Vendors.fields.Status",
        list: {
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: "Active" },
                { value: "Inactive" }
            ],
            defaultValue: "Active",
            required: true
        }
    },
   
    // {
    //     query: "profiles!inner(Name)",
    //     field: "profiles-Name",
    //     flex: 1,
    //     header: "Vendors.Accounts.Coordinator",
    //     list: {}
    // },
    // {
    //     field: "profiles_id",
    //     header: "Notifications.fields.Coordinator",
    //     edit: {
    //         show: true,
    //         widget: "AutoComplete",
    //         resource: "profiles",
    //         resourceField: "Name",
    //         required: true
    //     }
    // },
    {
        field: "InvoiceSource",
        // flex: 1,
        header: "Vendors.fields.InvoiceSource",
        list: {
        },
        edit:{
            widget: "SelectField",
            options: [
                { value: "Portal" },
                { value: "Email" },
                { value: "Scan" }
            ],
            defaultVaue: "Email"
        }
    },
    {
        field: "logins_count",
        flex: 1,
        header: "Vendors.Logins.Count",
        list: {
            filterVariant: 'range',
            filterFn: 'between',
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Accounts",
                            resource: "accounts", // resource name or identifier
                            action: "list"
                        }}
                        query={{
                            filters: [
                                {
                                    field: "vendors_id",
                                    operator: "eq",
                                    value: cell?.row?.id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        field: "accounts_count",
        flex: 1,
        header: "Vendors.Accounts.Count",
        list: {
            filterVariant: 'range',
            filterFn: 'between',
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Accounts",
                            resource: "accounts", // resource name or identifier
                            action: "list"
                        }}
                        query={{
                            filters: [
                                {
                                    field: "vendors_id",
                                    operator: "eq",
                                    value: cell?.row?.id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        field: "DomainSuffix",
        // flex: 1,
        header: "Vendors.fields.DomainSuffix",
        list: {
        },
        edit:{
            defaultValue: ""
        }
    },
    // {
    //     field: "EmailMajorFolder",
    //     // flex: 1,
    //     header: "Vendors.fields.EmailMajorFolder",
    //     list: {
    //         // minWidth: 200,
    //     },
    //     edit:{
    //         defaultValue: ""
    //     }
    // },


];


