

const accessControlProvider =
{
    can: async ({ resource, action, params }) => {

        const identity = JSON.parse(localStorage.getItem("profile"));
        if (identity?.Features.split(',').includes(params?.resource.identifier) && action === "list") {
            return { can: true };
        } else if (params?.resource.identifier == "Dashboard") {
            return { can: true };
        } else if ((action === "edit") || (action === "create") || (action === "delete") || (action === "show")) {
            return { can: true };
        } else {
            return {
                can: false,
                reason: "Unauthorized",
            };
        }
    },
    options: {
        buttons: {
            enableAccessControl: true,
            hideIfUnauthorized: false,
        },
    },
}

export default accessControlProvider;
