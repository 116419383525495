
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const usersTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];

import { supabaseClient } from "utility";


const featureOptions = [
    { value: "Notifications" },
    { value: "MyNotifications" },
    { value: "Vendors" },
    { value: "MyVendors" },
    { value: "Logins" },
    { value: "MyLogins" },
    { value: "Accounts" },
    { value: "MyAccounts" },
    { value: "Bills" },
    { value: "MyBills" },
    { value: "Payments" },
    { value: "MyPayments" },
    { value: "Downloaders" },
    { value: "EmailRules" },
    { value: "Users" },
    { value: "Circuits" },
    { value: "Reports" },
    { value: "Profile" },
    { value: "LoginsQueue" }
];


export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Users.fields.id",
        type: "number",
        list: {
            show: false
        },
        edit: {
            show: false
        }
    },
    {
        field: "username",
        header: "Users.fields.Username",
        search: true,
        list: {
        },
        edit: {
            required: true
        }

    },
    {
        field: "Name",
        header: "Users.fields.Name",
        list: {
        },
        edit: {
            gridcol: 2,
            required: true
        }
    },
    // {
    //     field: "EmailCategory",
    //     header: "Users.fields.EmailCategory",
    //     list: {
    //     },
    //     edit: {
    //     }
    // },
    // {
    //     field: "EmailFolder",
    //     header: "Users.fields.EmailFolder",
    //     list: {
    //     },
    //     edit: {
    //     }
    // },
    {
        field: "Features",
        header: "Users.fields.Features",
        list: {
            minSize: 100,
            size: 700,
            Cell: ({ cell }) =>
                <ChipField selected={cell.getValue()?.split(',')} options={featureOptions} />

        },
        edit: {
            widget: "MultipleSelectField",
            options: featureOptions,
            defaultValue: "MyNotifications",
            required: true
        }
    }
];


