
import {
    AuthPage,
    ThemedTitleV2,
    ErrorComponent,
    notificationProvider,
    RefineSnackbarProvider,
    ThemedLayoutV2,
} from "@refinedev/mui";



export default function LoginPage() {

    return (
        <AuthPage
            type="login"
            title={
                <ThemedTitleV2
                    collapsed={false}
                    text="VendorDocs Login"
                    icon={<img src="/images/VendorDocs.png" alt="Theia VendorDocs" />} />}
            formProps={{
                defaultValues: {
                    email: "",
                    password: "",
                },
            }}
        />

    )


}