
import { ChipField } from "../../components/ChipField";

import { GoLink } from "../../components/GoLink";

import StampText from "components/StampText";

export const vendorTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];


export const columnConfig = [
    {
        field: "id",
        header: "Locations.fields.id",
        type: "number",        
        list: {
            show: false
        },
        edit: {
            show: false
        }

    },
    {
        field: "Name",
        header: "Locations.fields.Name",
        search: true,
        list: {
        },
        edit: {
            required: true
        }
    },
    {
        field: "Stamp",
        header: "Locations.fields.Stamp",
        // search: true,
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} /> 
        },
        edit: {
            widget: "StampField"
        }
    },
];


