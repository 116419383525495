import React, { useState } from "react";

import { Box, TextField, Autocomplete, Select, InputLabel, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/

interface Props {
    id?: number,
    name?: any,
    label?: any,
    control?: any,
    defaultValue?: string,
    required?: any,
    error?: any,
    helperText?: any,
    options?: any,
    variant?: string,
    margin?: string,
    props?: any
}

const SelectField = ({
    name,
    label,
    control,
    defaultValue,
    required,
    error,
    helperText,
    options,
    ...props
}: Props) => {


    const labelId = `${name}-label`;

    return (
        <FormControl {...props} fullWidth>
            <InputLabel required={required} id={labelId}>{name}</InputLabel>
            <Controller
                control={control}
                defaultValue={defaultValue }
                rules={{ required: required }}
                name={name}
                
                render={({ field }) => {
                    return (
                        <>
                            <Select
                                {...field}
                                value={field.value ? field.value : ""}
                                labelId={labelId}
                                id={name}
                                fullWidth
                                label={name}
                                name={name}
                                error={error}
                                required={required}
                            >
                                {options.map((item: any) => (<MenuItem key={item.value} value={item.value}>{item.label || item.value}</MenuItem>))}
                            </Select>
                            { error && <FormHelperText>{helperText}</FormHelperText> }
                        </>
                    )
                }}
            />
        </FormControl>
    )
}

export default SelectField;