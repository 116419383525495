
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const NotificationsTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];


import dayjs from "dayjs";


export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Notifications.fields.id",
        type: "number",
        list: {
            show: false
        },
        edit: {
            show: false
        }

    },
    {
        field: "title",
        header: "Notifications.fields.Title",
        search: true,
        list: {
        },
        edit: {
            required: true
        }
    },
    {
        field: "Category",
        header: "Notifications.fields.Category",
        list: {
        },
        edit: {
            widget: "SelectField",
            options: [
                { id: "Portal", value: "Portal" },
                { id: "Account", value: "Account" },
                { id: "New Circuit", value: "New Circuit" },
                { id: "Cancelled Circuit", value: "Cancelled Circuit" },
            ],
            required: true

        }
    },
    {
        query: "profiles!inner(Name)",
        field: "profiles-Name",
        header: "Notifications.fields.Coordinator",
        list: {
            flex: 1,
            // minWidth: 200,
            // renderCell: function render({ value }) {
            //     console.log("VALUE", value);
            //     return (value?.Name)
            // }
        },
    },
    {
        field: "profiles_id",
        header: "Notifications.fields.Coordinator",
        // search: true,
        edit: {
            show: true,
            widget: "AutoComplete",
            resource: "profiles",
            resourceField: "Name",
            required: true
        }
    },
    {
        field: "Details",
        header: "Notifications.fields.Details",
        // search: true,
        list: {
            show: false
        },
        edit: {
            widget: "TextArea"
        }
    },
    {
        field: "Resolved",
        // flex: 1,
        header: "Notifications.fields.Resolved",
        list: {
            filterFn: "is",
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
                cell.getValue() === true ? 'Yes' : 'No'
        },
        edit: {
            widget: "Checkbox"
        }
    },
    {
        field: "Notes",
        header: "Notifications.fields.Notes",
        // search: true,
        list: {
            show: false
        },
        // editOptions: {
        //     default: "",
        //     type: "string",
        //     title: "Details",
        //     ui: {
        //       "ui:disabled": !(username === ""),
        //       "ui:widget": "textarea",
        //     },
        //   },
        edit: {
            widget: "TextArea"
        }
    },
    {
        field: "created_at",
        header: "Notifications.fields.CreatedAt",
        list: {
            Cell: ({ cell }) => {
                return dayjs(cell.getValue()).format("YYYY-MM-DD h:mm A");
            }

        }
        // editOptions: {
        //     default: "",
        //     type: "string",
        //     title: "Details",
        //     ui: {
        //       "ui:disabled": !(username === ""),
        //       "ui:widget": "textarea",
        //     },
        //   },
    },
    {
        field: "updated_at",
        header: "Notifications.fields.UpdatedAt",
        list: {
            Cell: ({ cell }) => {
                return dayjs(cell.getValue()).format("YYYY-MM-DD h:mm A");
            }
        }
        // editOptions: {
        //     default: "",
        //     type: "string",
        //     title: "Details",
        //     ui: {
        //       "ui:disabled": !(username === ""),
        //       "ui:widget": "textarea",
        //     },
        //   },
    },
];


