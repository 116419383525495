
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const accountsTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];

import StampText from "components/StampText";

import { GoLink } from "../../components/GoLink";

export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Accounts.fields.id",
        type: "number",
        list: {
            show: false
        }
    },
    {
        field: "Name",
        header: "Accounts.fields.Name",
        search: true,
        list: {},
        edit:
            { required: true }
    },
    // {
    //     // query: "vendors(Name,coordinator_id,coordinator)",
    //     query: "vendors!inner(Name,coordinator_id,coordinator)",
    //     field: "vendors-coordinator_id",
    //     header: "Accounts.fields.Coordinator_id",
    //     list: {
    //         show: false
    //     }
    // },
    // {
    //     query: null,
    //     field: "vendors-coordinator",
    //     header: "Accounts.fields.Coordinator",
    //     list: {
    //         show: false
    //     }
    // },

    // {
    //     query: null,
    //     field: "vendors-Name",
    //     header: "Accounts.fields.Vendor",
    //     list: {}
    // },
    {
        query: "vendors(Name)",
        // query: "logins!inner(Name)",
        field: "vendors-Name",
        // flex: 1,
        header: "Accounts.fields.Vendor",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Vendors",
                            resource: "vendors", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "id",
                                    operator: "eq",
                                    value: cell?.row?.original?.logins_id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        field: "vendors_id",
        header: "Accounts.fields.Vendor",
        // search: true,
        edit: {
            show: true,
            widget: "AutoComplete",
            resource: "vendors",
            resourceField: "Name",
            required: true
        }
    },
    {
        query: "logins(Name)",
        // query: "logins!inner(Name)",
        field: "logins-Name",
        // flex: 1,
        header: "Accounts.fields.Login",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Logins",
                            resource: "logins", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "id",
                                    operator: "eq",
                                    value: cell?.row?.original?.logins_id,
                                },
                            ],
                        }}
                        type="push"
                        value={cell.getValue()}
                    />
                )
            }
        }
    },
    {
        field: "logins_id",
        header: "Accounts.fields.Login",
        list: {
            show: false
        },
        // search: true,
        edit: {
            show: true,
            widget: "AutoComplete",
            resource: "logins",
            resourceField: "Name",
            defaultValue: null
        }
    },
    {
        field: "Stamp",
        header: "Clients.fields.Stamp",
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} />
        },
        edit: {
            gridcol:2,
            widget: "StampField"
        }
    },
    {
        field: "Status",
        // flex: 1,
        header: "Accounts.fields.Status",
        list: {},
        edit: {
            widget: "SelectField",
            options: [
                { value: "Active" },
                { value: "Inactive" },
                { value: "Error" },
                { value: "Unsupported" },
                { value: "Suspended" }
            ],
            defaultValue: "Active",
            required: true
        }
    },
    {
        field: "StatusMessage",
        // flex: 1,
        header: "Accounts.fields.StatusMessage",
        list: {},
        edit: {}
    },
    {
        field: "documents_count",
        // flex: 1,
        header: "Accounts.fields.Bills",
        list: {
            Cell: ({ cell }) => {
                return (
                    <GoLink
                        to={{
                            identifier: "Bills",
                            resource: "documents", // resource name or identifier
                            action: "list",
                            // id: 1,
                        }}
                        query={{
                            filters: [
                                {
                                    field: "accounts_id",
                                    operator: "eq",
                                    value: cell?.row?.id,
                                },
                            ],
                        }}
                        type="push"
                        // value={"JAS2"}
                        value={cell.getValue()}
                    />
                )
            }

        },
    },

    // {
    //     field: "AutoPay",
    //     // flex: 1,
    //     header: "Accounts.fields.AutoPay",
    //     list: {
    //         filterFn: "is",
    //         filterVariant: 'checkbox',
    //         Cell: ({ cell }) =>
    //             cell.getValue() === true ? 'Yes' : 'No'
    //     },
    //     edit: {
    //         widget: "Checkbox"
    //     }
    // },
    // {
    //     query: "payment_methods(Name)",
    //     // query: "payment_methods!inner(Name)",
    //     field: "payment_methods-Name",
    //     // flex: 1,
    //     header: "Accounts.fields.PaymentMethods",
    //     list: {},

    //     // edit: {
    //     //     widget: "SelectField",
    //     //     options: [
    //     //         { value: "-" },
    //     //         { value: "DirectPayment" },
    //     //         { value: "ChequePayment" },
    //     //         { value: "PostOnly" },
    //     //         { value: "CreditCardPayment" }
    //     //     ],
    //     //     defaultValue: "-",
    //     // }
    // },
    // {
    //     field: "payment_methods_id",
    //     header: "Accounts.fields.PaymentMethods",
    //     // search: true,
    //     edit: {
    //         show: true,
    //         widget: "AutoComplete",
    //         resource: "payment_methods",
    //         resourceField: "Name",
    //         // required: true
    //     }
    // },


    {
        // query: "last_docdate",
        field: "last_docdate",
        // flex: 1,
        header: "Accounts.fields.Last_Docdate",
        list: {
        }
    },
    // {
    //     field: "Last_Invoice_Synced",
    //     // flex: 1,
    //     header: "Accounts.fields.Last_Invoice_Synced",
    //     list: {
    //         filterFn: "is",
    //         filterVariant: 'checkbox',
    //         Cell: ({ cell }) =>
    //             cell.getValue() === true ? 'Yes' : 'No'
    //     }
    // },
    // {
    //     field: "Last_Invoice_Posted",
    //     // flex: 1,
    //     header: "Accounts.fields.Last_Invoice_Posted",
    //     list: {
    //         filterFn: "is",
    //         filterVariant: 'checkbox',
    //         Cell: ({ cell }) =>
    //             cell.getValue() === true ? 'Yes' : 'No'
    //     }
    // },

];


