import React, { useState } from "react";

import { Box, TextField, Autocomplete, Select, InputLabel, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { ChipField } from "./ChipField";

import { Controller } from "react-hook-form";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/

interface Props {
    id?: number,
    name?: any,
    label?: any,
    control?: any,
    defaultValue?: string,
    required?: any,
    error?: any,
    helperText?: any,
    options?: any,
    variant?: string,
    margin?: string,
    props?: any
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const MultipleSelectField = ({
    name,
    label,
    control,
    defaultValue,
    required,
    error,
    helperText,
    options,
    ...props
}: Props) => {


    const labelId = `${name}-label`;

    return (
        <FormControl {...props}>
            <InputLabel required={required} id={labelId}>{name}</InputLabel>
            <Controller
                control={control}
                defaultValue={defaultValue }
                rules={{ required: required }}
                name={name}
                render={({ field }) => {
                    const myvalue = field.value.split(",");
                    return (
                        <>
                            <Select
                                {...field}
                                // value={field.value ? field.value : ""}
                                labelId={labelId}
                                id={name}
                                multiple
                                fullWidth
                                label={name}
                                name={name}
                                error={error}
                                required={required}
                                value={myvalue}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    field.onChange(value.join(","));
                                    console.log("VALUE CHANGED TO", value);
                                }}
                                renderValue={(selected) => (
                                    <ChipField selected={selected} options={options} />
                                )}
                                MenuProps={MenuProps}


                            >
                                {options.map((item: any) => (<MenuItem key={item.value} value={item.value}>{item.label || item.value}</MenuItem>))}
                            </Select>
                            { error && <FormHelperText>{helperText}</FormHelperText> }
                        </>
                    )
                }}
            />
        </FormControl>
    )
}

export default MultipleSelectField;