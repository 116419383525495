import React, { useRef, useCallback } from "react";

import { Box, TextField, Autocomplete, Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
// import { useAutocomplete } from "@refinedev/mui";
import { useAutocomplete } from "./useAutoComplete";


interface Props {
    id?: number,
    control?: any,
    resource?: string,
    name?: string,
    field?: string,
    label?: string,
    defaultValue?: any,
    required?: any,
    error?: any,
    helperText?: any,
    multiple?: boolean
}


const AutoCompleteField = ({ id, control, resource, name, field, label, defaultValue, required, error, helperText, multiple }: Props) => {

    // const observer = useRef();

    // const lastOptionElementRef = useCallback((node) => {
    //     if (observer.current) observer.current.disconnect();
    //         observer.current = new IntersectionObserver(async (entries) => {
    //             if (entries[0].isIntersecting && props.hasMore) {
    //                 setPageNumber((pageNumber) => pageNumber + 1);
    //             }
    //         });
    //     if (node) observer.current.observe(node);
    // }, [props.loader]);

    let _defaultValue = defaultValue;
    if (multiple) {
        console.log("AUTOCOMPLETE DEFAULT", defaultValue)
        _defaultValue = defaultValue ? JSON.parse(defaultValue) : [];
    }

    const { autocompleteProps: fieldAutocompleteProps } = useAutocomplete({
        resource: resource,
        defaultValue: _defaultValue,
        meta: {
            select: `id,${name}`,
        },
        sorters: [
            {
                field: name,
                order: "asc"
            }
        ],
        onSearch: (value) => [
            {
                field: name,
                operator: "contains",
                value,
            },
        ],
        filters: [
            {
                field: name,
                operator: "nnull",
                value: "null"
            },
            {
                field: name,
                operator: "ne",
                value: ""
            }
        ],
        debounce: 500,
        pagination: { current: 1, pageSize: 50, mode: "client" },
    });

    return (
        <Controller
            control={control}
            name={field}
            rules={{ required: required }}
            // eslint-disable-next-line
            // defaultValue={null as any}
            defaultValue={_defaultValue || null}
            render={({ field }) => {
                return (
                    <Autocomplete
                        {...fieldAutocompleteProps}
                        {...field}


                        // multiple
                        multiple={multiple || false}
                        onChange={(_, value) => {
                            // console.log("ON CHANGE", value);
                            field.onChange(value?.id ?? value);
                        }}
                        getOptionLabel={(item) => {
                            return (
                                fieldAutocompleteProps?.options?.find(
                                    (p) =>
                                        p?.id?.toString() ===
                                        (item?.id ?? item)?.toString(),
                                )?.[name] ?? ""
                            );
                        }}
                        isOptionEqualToValue={(option, value) => {
                            // console.log("isOptionEqualToValue", option, value)
                            return value === undefined ||
                                option?.id?.toString() ===
                                (value?.id ?? value)?.toString()
                        }}
                        onInputChange={(_evt, newValue) => { console.log("NEW VALUE", newValue) }}

                        // renderOption={(props, option, state, ownerState) => {
                        // console.log("PROPS", props)
                        // console.log("option", option)
                        // console.log("state", state)
                        // console.log("ownerState", ownerState)
                        // }
                        // }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                margin="normal"
                                variant="outlined"
                                error={error}
                                helperText={helperText}
                                required={required ? true : false}
                            />
                        )}
                    />
                )
            }}
        />)
}

export default AutoCompleteField;