import { useGo } from "@refinedev/core";

import Link from "@mui/material/Link";

interface Props {
    to?: number,
    query?: any,
    type?: any,
    value?: any,
}

export const GoLink = ({ to, query, type, value }: Props) => {
    const go = useGo();
    if (value) {
        return (
            <Link
                component="button"
                target="_"
                variant="body2"
                onClick={() => {
                    go({
                        to: to,
                        query: query,
                        type: type
                    });
                }}
            >
                {value}
            </Link>
        );
    } else {
        return (<>{value}</>)
    }

};