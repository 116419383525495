import React from "react";

import { Box, TextField, Autocomplete, Checkbox, Select, InputLabel, MenuItem, FormControl, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import fileDownload from "js-file-download";

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/

interface Props {
    id?: number,
    name?: any,
    label?: any,
    control?: any,
    defaultValue?: boolean,
    error?: any,
    helperText?: any,
    options?: any,
    variant?: string,
    margin?: string,
    // children,
    props?: any
}

const BooleanField = ({
    name,
    label,
    control,
    defaultValue,
    error,
    helperText,
    options,
    ...props
}: Props) => {

    return (
        <FormControl
            {...props}
        >
            <FormControlLabel
                ref={null}
                label={label}
                control={
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => {
                            return (
                                <Checkbox
                                    // checked={true}
                                    checked={field.value ? field.value : defaultValue}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                />
                            )
                        }
                        }
                    />
                }
            />
        </FormControl>
    )
}

export default BooleanField;