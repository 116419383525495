

interface Props {
    data?: any,
    prefix?: string,
    props?: any
}

const stampFields = ["Date Received", "Job / Department #", "Group", "Company Name", "Vendor I.D.", "PO / Sub #", "Activity / Cost Code"];

const StampText = ({
    data,
    prefix='Stamp-'
}: Props) => {

    var myvalue = []
    stampFields.forEach((key) => {
        if (`${prefix}${key}` in data) {
            const valuestring = (<><i>{key}</i>:{data[`${prefix}${key}`]}</>)
            myvalue.push(valuestring)
        } 

    })
    return (myvalue.length ? myvalue.reduce((prev, curr) => [prev, ', ', curr]) : <></>)
}

export default StampText;