
import React from 'react';

import { columnConfig } from "./config";

import { RemoteDataTable } from 'components/RemoteDataTable';

import {
    IResourceComponentsProps
} from "@refinedev/core";


// import internal from "stream";

export const DownloadersList: React.FC<IResourceComponentsProps> = () => {

    return (<RemoteDataTable
        resource={"downloaders"}
        ColumnConfig={columnConfig}
        createItem={false}
        editItem={false}
    />)
}
