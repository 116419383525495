
import React, { useEffect, useMemo, useState } from 'react';

import { columnConfig } from "./config";

import { RemoteDataTable } from 'components/RemoteDataTable';

import {
    IResourceComponentsProps,
} from "@refinedev/core";

import { FormControl, OutlinedInput, InputLabel, Select, MenuItem } from "@mui/material";

export const LocationsList: React.FC<IResourceComponentsProps> = () => {


    // const [selectedStatus, setSelectedStatus] = useState("Active")
    // const [baseFilters, setBaseFilters] = useState([]);

    // const handleChange = (event) => {
    //     const { target: { value }, } = event;
    //     setSelectedStatus(value);
    // }

    // useEffect(() => {
    //     var base_filters: any[] = [];
    //     if (selectedStatus === "Active") {
    //         base_filters.push({
    //             field: "Status",
    //             operator: "eq",
    //             value: "Active"
    //         })
    //     } else if (selectedStatus === "Non-Active") {
    //         base_filters.push({
    //             field: "Status",
    //             operator: "neq",
    //             value: "Active"
    //         })
    //     }
    //     setBaseFilters(base_filters);
    // }, [, selectedStatus]);

    
    // const StatusSelectField = () => {    
    //     return (
    //         <FormControl variant="standard" size="small">
    //             {/* <InputLabel id="vendor-select-combo-label">Status</InputLabel> */}
    //             <Select
    //                 labelId="vendor-select-combo-label"
    //                 id="vendor-select-combo"
    //                 // defaultValue={"Active"}
    //                 value={selectedStatus}
    //                 onChange={handleChange}
    //                 input={<OutlinedInput/>}
    //                 // label="Status"
    //             >
    //                 <MenuItem value={"Active"}>Active</MenuItem>
    //                 <MenuItem value={"Non-Active"}>Non-Active</MenuItem>
    //                 <MenuItem value={"All"}>All</MenuItem>
    //             </Select>
    //          </FormControl>    
    //     )
    // }

    return (<RemoteDataTable
        resource={"locations"}
        ColumnConfig={columnConfig}
        // statusSelectComponent={<StatusSelectField/>}
        // base_filters={baseFilters}
        initial_sorting={[{id: 'id', order:'asc'}]}
    />)
}
