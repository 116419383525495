import React from "react";

import { Box, TextField, Autocomplete, Select, InputLabel, MenuItem, FormControl, Chip } from "@mui/material";

interface Props {
    selected?: any,
    options?: any
}

export const ChipField = ({ selected, options }: Props) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected &&
                selected.map((value: any) => {
                    const option = options.find((item: any) => item.value === value);
                    return (<Chip key={value} label={value}
                        style={{ backgroundColor: option?.color }}
                    />)
                })}
        </Box>
    )
}





