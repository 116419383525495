import React, { useState, useEffect } from "react";
import { Edit } from "@refinedev/mui";
import { Box, TextField, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate, useSelect, useParsed } from "@refinedev/core";

import EditForm from "../../components/EditForm"


import SelectField from "../../components/SelectField"
import BooleanField from "../../components/BooleanField"
import AutoCompleteField from "../../components/AutoCompleteField";

// import { statusOptions, invoiceSourceOptions } from "./config";


import { columnConfig } from "./config";
import internal from "stream";


export const EmailRulesEdit: React.FC<IResourceComponentsProps> = () => {

  const resource = "email_rules";

  const { id } = useParsed();

  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish, redirect, mutationResult },
    register,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: resource,
      id,
      action: "edit",
    }
  }
  );

  const [columnData, setColumnData] = useState(queryResult?.data?.data);

  useEffect(() => {
    setColumnData(queryResult?.data?.data);
  },[queryResult]);
  // const handleSubmit2 = () => {

  //   // e.preventDefault();

  //   const { data } = mutationResult;
  //   console.log("DATA", data)
  //   const data2 = await onFinish(data);
  //   console.log("DATA2", data2)
  //   redirect("list");
  // };

  return (
    <Edit
      saveButtonProps={saveButtonProps} >

      <EditForm
        resource={resource}
        columnConfig={columnConfig}
        columnData={columnData}
        // columnData={queryResult?.data?.data}
        register={register}
        control={control}
        errors={errors}
        type={"edit"}
      />
    </Edit>

  )
};
