import { Refine, Authenticated, WelcomePage, useGetIdentity } from "@refinedev/core";
// import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  AuthPage,
  ThemedTitleV2,
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
} from "@refinedev/mui";


import LoginPage from "./pages/LoginPage";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

import { Header } from "./components/header";

import { dataProvider, liveProvider } from "@refinedev/supabase";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { supabaseClient } from "utility";

import authProvider from "./authProvider";
import accessControlProvider from "./accessControlProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";

import AppTitle from "./AppTitle"

import { DashboardPage } from "pages/Dashboard";

import { VendorsList, VendorsEdit, VendorsCreate } from "pages/Vendors";
import { ClientsList, ClientsEdit, ClientsCreate } from "pages/Clients";
import { LocationsList, LocationsEdit, LocationsCreate } from "pages/Locations";
import { NotificationsList, NotificationsEdit, NotificationsCreate } from "pages/Notifications";
import { LoginsList, LoginsEdit, LoginsCreate } from "pages/Logins";
import { AccountsList, AccountsEdit, AccountsCreate } from "pages/Accounts";
import { DownloadersList } from "pages/Downloaders";
import { EmailRulesList, EmailRulesEdit, EmailRulesCreate } from "pages/EmailRules";
import { UsersList, UsersEdit } from "pages/Users";
import { BillsList, BillsShow } from "pages/Bills";
import { Profile } from "pages/Users/profile";


import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Cottage";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DescriptionIcon from '@mui/icons-material/Description';
// import BuildIcon from "@mui/icons-material/Build";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

import { useEffect, useState } from "react";
import { translateActionTitle } from "@refinedev/inferencer/dist/utilities";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            {/* <DevtoolsProvider> */}
              <Refine
                dataProvider={dataProvider(supabaseClient)}
                liveProvider={liveProvider(supabaseClient)}
                authProvider={authProvider}
                routerProvider={routerBindings}
                notificationProvider={notificationProvider}
                i18nProvider={i18nProvider}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  projectId: "WzDyqG-Bwwpsw-IPmD1X",
                  liveMode: "auto",
                }}
                Title={({ collapsed }) => (<AppTitle collapsed={collapsed} />)}
                accessControlProvider={accessControlProvider}
                resources={[
                  {  icon: <DashboardOutlinedIcon />, identifier: "Dashboard", name: "Dashboard", list: "/", meta: { label: "Dashboard" }},
                  { icon: <ReceiptLongIcon />, identifier: "Notifications", name: "notifications", list: "/notifications", create: "/notifications/create", edit: "/notifications/edit/:id", show: "/notifications/show/:id" },
                  { icon: <BusinessIcon />, identifier: "Vendors", name: "vendors", list: "/vendors", create: "/vendors/create", edit: "/vendors/edit/:id", show: "/vendors/show/:id" },
                  { icon: <BusinessIcon />, identifier: "Companies", name: "clients", list: "/companies", create: "/companies/create", edit: "/companies/edit/:id", show: "/companies/show/:id" , meta: { label: "Companies" }},
                  { icon: <LoginIcon />, identifier: "Logins", name: "logins", list: "/logins", create: "/logins/create", edit: "/logins/edit/:id", show: "/logins/show/:id" },
                  { icon: <ReceiptLongIcon />, identifier: "Accounts", name: "accounts", list: "/accounts", create: "/accounts/create", edit: "/accounts/edit/:id", show: "/accounts/show/:id" },
                  { icon: <BusinessIcon />, identifier: "Properties", name: "locations", list: "/properties", create: "/properties/create", edit: "/properties/edit/:id", show: "/properties/show/:id", meta: { label: "Properties" } },
                  { icon: <ReceiptLongIcon />, identifier: "Bills", name: "documents", list: "/bills", show: "/bills/show/:id", meta: { label: "Bills" } },
                  { icon: <BrowserUpdatedIcon />, identifier: "Downloaders", name: "downloaders", list: "/downloaders", create: "/downloaders/create", edit: "/downloaders/edit/:id", show: "/downloaders/show/:id" },
                  { icon: <ForwardToInboxIcon />, identifier: "EmailRules", name: "email_rules", list: "/emailrules", create: "/emailrules/create", edit: "/emailrules/edit/:id", show: "/emailrules/show/:id", meta: { label: "Email Rules" } },
                  { icon: <PeopleAltIcon />, identifier: "Users", name: "profiles", list: "/users", create: "/users/create", edit: "/users/edit/:id", show: "/users/show/:id", meta: { label: "Users" } },
                  { icon: <PersonIcon />, identifier: "Profile", name: "profiles", list: "/profile", meta: { label: "Profile" } },
                ]}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                        <ThemedLayoutV2 Header={() => <Header isSticky={true} />}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    {/* <Route
                    index
                    element={<NavigateToResource resource="accounts" />}
                  /> */}
                    <Route index element={<DashboardPage />} />
                    <Route path="/vendors">
                      <Route index element={<VendorsList />} />
                      <Route path="create" element={<VendorsCreate />} />
                      <Route path="edit/:id" element={<VendorsEdit />} />
                    </Route>
                    <Route path="/companies">
                      <Route index element={<ClientsList />} />
                      <Route path="create" element={<ClientsCreate />} />
                      <Route path="edit/:id" element={<ClientsEdit />} />
                    </Route>
                    <Route path="/properties">
                      <Route index element={<LocationsList />} />
                      <Route path="create" element={<LocationsCreate />} />
                      <Route path="edit/:id" element={<LocationsEdit />} />
                    </Route>
                    {/* <Route path="/myvendors">
                      <Route index element={<VendorsList />} />
                      <Route path="create" element={<VendorsCreate />} />
                      <Route path="edit/:id" element={<VendorsEdit />} />
                    </Route> */}
                    <Route path="/accounts">
                      <Route index element={<AccountsList />} />
                      <Route path="create" element={<AccountsCreate />} />
                      <Route path="edit/:id" element={<AccountsEdit />} />
                      {/* <Route path="show/:id" element={<VendorsShow />} /> */}
                    </Route>
                    <Route path="/myaccounts">
                      <Route index element={<AccountsList />} />
                      <Route path="create" element={<AccountsCreate />} />
                      <Route path="edit/:id" element={<AccountsEdit />} />
                      {/* <Route path="show/:id" element={<VendorsShow />} /> */}
                    </Route>
                    <Route path="/bills">
                      <Route index element={<BillsList />} />
                      <Route path="show/:id" element={<BillsShow />} />
                    </Route>
                    <Route path="/mybills">
                      <Route index element={<BillsList />} />
                      {/* <Route path="show/:id" element={<BillsShow />} /> */}
                    </Route>
                    <Route path="/notifications">
                      <Route index element={<NotificationsList />} />
                      <Route path="create" element={<NotificationsCreate />} />
                      <Route path="edit/:id" element={<NotificationsEdit />} />
                    </Route>
                    <Route path="/mynotifications">
                      <Route index element={<NotificationsList />} />
                      <Route path="create" element={<NotificationsCreate />} />
                      <Route path="edit/:id" element={<NotificationsEdit />} />
                    </Route>
                    <Route path="/logins">
                      <Route index element={<LoginsList />} />
                      <Route path="create" element={<LoginsCreate />} />
                      <Route path="edit/:id" element={<LoginsEdit />} />
                    </Route>
                    <Route path="/mylogins">
                      <Route index element={<LoginsList />} />
                      <Route path="create" element={<LoginsCreate />} />
                      <Route path="edit/:id" element={<LoginsEdit />} />
                    </Route>
                    <Route path="/downloaders">
                      <Route index element={<DownloadersList />} />
                      {/* <Route path="create" element={<DownloadersCreate />} />
                    <Route path="edit/:id" element={<DownloadersEdit />} />
                    <Route path="show/:id" element={<DownloadersShow />} /> */}
                    </Route>
                    <Route path="/emailrules">
                      <Route index element={<EmailRulesList />} />
                      <Route path="create" element={<EmailRulesCreate />} />
                      <Route path="edit/:id" element={<EmailRulesEdit />} />
                      {/* <Route path="show/:id" element={<EmailRulesShow />} /> */}
                    </Route>
                    <Route path="/users">
                      <Route index element={<UsersList />} />
                      <Route path="edit/:id" element={<UsersEdit />} />
                      {/* <Route path="create" element={<VendorsCreate />} />
                      <Route path="show/:id" element={<VendorsShow />} /> */}
                    </Route>
                    <Route path="/profile">
                      <Route index element={<Profile />} />
                      {/* <Route path="edit/:id" element={<UsersEdit />} /> */}
                      {/* <Route path="create" element={<VendorsCreate />} />
                      <Route path="show/:id" element={<VendorsShow />} /> */}
                    </Route>

                  </Route>

                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route
                      path="/login"
                      element={<LoginPage />}
                    />
                    <Route
                      path="/register"
                      element={<AuthPage type="register" />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<AuthPage type="forgotPassword" />}
                    />
                  </Route>
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
              {/* <DevtoolsPanel />
            </DevtoolsProvider> */}
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
