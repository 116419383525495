
import React from 'react';

import { columnConfig } from "./config";

import { RemoteDataTable } from 'components/RemoteDataTable';

import {
    IResourceComponentsProps
} from "@refinedev/core";

export const EmailRulesList: React.FC<IResourceComponentsProps> = () => {
    return (<RemoteDataTable
        resource={"email_rules"}
        ColumnConfig={columnConfig}
    />)
}
