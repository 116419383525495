
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

import dayjs from "dayjs";

import axios from "axios";
import fileDownload from "js-file-download";
import Link from "@mui/material/Link";

import StampText from "components/StampText";
import { supabaseClient } from "utility";

// export const BillsTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];

const handleDownload = (record) => {

    if ('url' in record) {
        
        let https_url = record.url.replace("http:", "https:")

        https_url = https_url.replace('https://nocodb.thinkbeyond.ai/download/https://nocodb_thinkbeyond_ai/api/v1/db/data/noco/VendorDocs/Transactions/Documents', 'https://vendordocs.thinkbeyond.ai/download/noco/VendorDocs/Transactions/Documents/Documents' )

        https_url = https_url.replace('nocodb', "vendordocs");

        axios
            .get(https_url, {
                responseType: "blob",
            })
            .then((res) => {
                fileDownload(res.data, record.title);
            });
    } else if ('bucket' in record) {
        console.log("SUPABASE BUCKET DOWNLOAD", record)

        supabaseClient
            .storage
            .from(record.bucket)
            .download(record.path)
            .then(({data, error}) => {
                if (error){
                    console.log("DOWNLOAD ERROR!", error.toString())
                    // open?.({
                    //     type: "error",
                    //     message: "Download Error",
                    //     description: error.toString()
                    // });    
                }else{
                    console.log ("RESPONSE DATA",data);
                    fileDownload(data, record.title);    
                }
                
            })
    }

};


export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "Bills.fields.id",
        type: "number",
        list: {
            show: false
        }
    },
    {
        query: "accounts!documents_accounts_id_fkey(Name)",
        field: "accounts-Name",
        header: "Bills.fields.Account",
        list: {}
    },
    // {
    //     field: "accounts_id",
    //     header: "Bills.fields.Account",
    //     // search: true,
    //     edit: {
    //         show: true,
    //         widget: "AutoComplete",
    //         resource: "accounts",
    //         resourceField: "Name",
    //         required: true
    //     }
    // },    
    {
        // query: null,
        field: "vendor_name",
        header: "Bills.fields.Vendor",
        list: {}
    },
    // {
    //     // query: null,
    //     field: "coordinator_id",
    //     header: "Bills.fields.coordinator_id",
    //     list: {}
    // },
    {
        field: "Docdate",
        header: "Bills.fields.Docdate",
        list: {},
        // edit:
        //     { required: true }
    },
    {
        field: "Reference",
        header: "Bills.fields.Reference",
        list: {},
        // edit:
        //     { required: true }
    },
    {
        field: "Documents",
        header: "Bills.fields.Documents",
        search: true,
        list: {
            Cell: ({ cell }) => {

                if (cell.getValue()) {


                    const documents = JSON.parse(cell.getValue());
                    return (
                        <>
                            {documents.map((export_file) => (
                                <div key={export_file.title} >
                                    <Link
                                        key={export_file.title}
                                        component="button"
                                        target="_"
                                        variant="body2"
                                        onClick={() => {
                                            handleDownload(export_file);
                                        }}
                                    >
                                        {/* {cell?.row?.original?.Reference} */}
                                        {export_file.title}
                                    </Link>
                                </div >
                            )
                            )}

                        </>
                    );
                } else {
                    return (<></>);
                }

            }
        },
        // edit:
        //     { required: true }
    },
    {
        field: "Stamp",
        header: "Bills.fields.Stamp",
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} /> 
        },
    },
    {
        field: "Synced",
        // flex: 1,
        header: "Bills.fields.Synced",
        list: {
            filterFn: "is",
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
                cell.getValue() === true ? 'Yes' : 'No'
        },
    },
    {
        field: "Posted",
        // flex: 1,
        header: "Bills.fields.Posted",
        list: {
            filterFn: "is",
            filterVariant: 'checkbox',
            Cell: ({ cell }) =>
                cell.getValue() === true ? 'Yes' : 'No'
        },
    },
    {
        field: "updated_at",
        header: "Bills.fields.Updated",
        list: {
            Cell: ({ cell }) => {
                return dayjs(cell.getValue()).format("YYYY-MM-DD");
            }
        },


        // edit:
        //     { required: true }
    },

];


