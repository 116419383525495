
import React from 'react';

import { columnConfig } from "./config";

import { RemoteDataTable } from 'components/RemoteDataTable';

import {
    IResourceComponentsProps,
} from "@refinedev/core";

export const BillsList: React.FC<IResourceComponentsProps> = () => {

    return (<RemoteDataTable
        resource={"documents"}
        ColumnConfig={columnConfig}
        showItem={true}
        createItem={false}
        editItem={false}
        initial_sorting={[{id: 'id', order:'asc'}]}
    />)
}
