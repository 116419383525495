
import React, { useEffect, useMemo, useState } from 'react';

import { columnConfig } from "./config";

import { RemoteDataTable } from 'components/RemoteDataTable';

import { useGetIdentity } from "@refinedev/core";


import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
} from "@refinedev/core";

// import internal from "stream";

export const UsersList: React.FC<IResourceComponentsProps> = () => {

    return (<RemoteDataTable 
        resource={"profiles"}
        ColumnConfig={columnConfig}
        base_filters={undefined}
        createItem={false}
        initial_sorting={[{id: 'id', order:'asc'}]}
        />)

}
