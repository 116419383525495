
import { CellTower } from "@mui/icons-material";
import { ChipField } from "../../components/ChipField";

// export const EmailRulesTypes = [{ value: "Telco", color: "#ce93d8" }, { value: "Non-Telco", color: "#ff9800" }, { value: "PO", color: "#03a9f4" }, { value: "Employee", color: "#03a9f4" }];

import StampText from "components/StampText";


export const statusTypes = [{ value: "Active" }, { value: "Inactive" }, { value: "Error" }, { value: "Unsupported" }, { value: "Suspended" }]

export const columnConfig = [
    {
        field: "id",
        header: "EmailRules.fields.id",
        type: "number",
        list: {
            show: false
        }
    },
    {
        field: "inbox_id",
        header: "EmailRules.fields.Inbox",
        list: {

            Cell: ({ cell }) =>
                cell.getValue() === '1f57963e-3cb1-48ac-9b3b-8e0fbb8833e7' ? 'accounting@westeinde.ca' : 'accounting@theiaparterners.com'
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: '1f57963e-3cb1-48ac-9b3b-8e0fbb8833e7', label: "accounting@westeinde.ca" },
                { value: '4f953247-42e8-4a42-8502-e67a0b140d34', label: "accounting@theiaparterners.com" }
            ],
            defaultValue: '1f57963e-3cb1-48ac-9b3b-8e0fbb8833e7'
        }

    },
    {
        query: "vendors!inner(Name)",
        field: "vendors-Name",
        header: "EmailRules.fields.Vendor",
        list: {
        }
    },
    {
        field: "vendors_id",
        header: "Logins.fields.Vendor",
        // search: true,
        edit: {
            gridcol: 2,
            show: true,
            widget: "AutoComplete",
            resource: "vendors",
            resourceField: "Name",
            defaultValue: 15
            // required: true
        }
    },
    {
        field: "FromFilter",
        header: "EmailRules.fields.FromFilter",
        search: true,
        list: {
        },
        edit: {}
    },
    {
        field: "SubjectFilter",
        header: "EmailRules.fields.SubjectFilter",
        search: true,
        list: {
        },
        edit: {}
    },
    {
        field: "BodyFilter",
        header: "EmailRules.fields.BodyFilter",
        search: true,
        list: {
            show: false
        },
        edit: {}
    },
    {
        field: "ForwardTo",
        header: "EmailRules.fields.ForwardTo",
        search: true,
        list: {
        },
        edit: {}
    },
    {
        field: "Categories",
        header: "EmailRules.fields.Categories",
        search: true,
        list: {
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: "Advertisement", label: "Account - Advertisement" },
                { value: "Alert", label: "Account - Alert" },
                { value: "Update Required", label: "Account - Update Required" },
                { value: "Reset Required", label: "Account - Reset Required" },
                { value: "Profile Updated", label: "Account - Profile Updated" },
                // Service Notification
                { value: "Installation", label: "Service - Installation" },
                { value: "Cancellation", label: "Service - Cancellation" },
                { value: "Suspension ", label: "Service - Suspension" },
                {
                    value: "Appointment",
                    label: "Service - Appointment",
                },
                // Billing Notification
                { value: "Bill Ready", label: "Billing - Bill Ready" },
                { value: "Invoice", label: "Billing - Invoice" },
                { value: "Payment Made", label: "Billing - Payment Made" },
                { value: "Payment Cancelled", label: "Billing - Payment Cancelled" },
                { value: "Overdue Bill", label: "Billing - Overdue Bill" },
            ],
            default: "Update Required",
            // required: true
        },
    },
    {
        field: "SaveFolder",
        header: "EmailRules.fields.SaveFolder",
        // search: true,
        list: {
            show: false
        },
        edit: {}
    },
    {
        field: "AttachmentFolder",
        header: "EmailRules.fields.AttachmentFolder",
        // search: true,
        list: {
            show: false
        },
        edit: {}
    },
    {
        field: "MailFolder",
        header: "EmailRules.fields.MailFolder",
        search: true,
        list: {
        },
        edit: {
            widget: "SelectField",
            options: [
                { value: "*Dated Folder" }
            ],
            // required: true
        }
    },
    {
        field: "Notes",
        header: "EmailRules.fields.Notes",
        // search: true,
        list: {
            show: false
        },
        edit: {}
    },
    {
        field: "Custom1",
        header: "EmailRules.fields.Stamp",
        // search: true,
        list: {
            Cell: ({ cell }) => <StampText data={cell?.row?.original} />
        },
        edit: {
            gridcol: 2,
            widget: "StampField"
        }
    }

    // editOptions: {
    //     type: "string",
    //     title: "Category",
    //     oneOf: [
    //       // Account Notification
    //       { const: "Advertisement", title: "Account - Advertisement" },
    //       { const: "Alert", title: "Account - Alert" },
    //       { const: "Update Required", title: "Account - Update Required" },
    //       { const: "Reset Required", title: "Account - Reset Required" },
    //       { const: "Profile Updated", title: "Account - Profile Updated" },
    //       // Service Notification
    //       { const: "Installation", title: "Service - Installation" },
    //       { const: "Cancellation", title: "Service - Cancellation" },
    //       { const: "Suspension ", title: "Service - Suspension" },
    //       {
    //         const: "Appointment",
    //         title: "Service - Appointment",
    //       },
    //       // Billing Notification
    //       { const: "Bill Ready", title: "Billing - Bill Ready" },
    //       { const: "Invoice", title: "Billing - Invoice" },
    //       { const: "Payment Made", title: "Billing - Payment Made" },
    //       { const: "Payment Cancelled", title: "Billing - Payment Cancelled" },
    //       { const: "Overdue Bill", title: "Billing - Overdue Bill" },
    //     ],
    //     default: "Update Required",
    //   },


];


